import './App.css';
import {NavBar, Features2x2, MarketingPricing, MarketingFooter} from "./ui-components";

function App() {
  return (
    <div className="App">
        <NavBar width={"100vw"} />
        <Features2x2 width={"100vw"} />
    <header className="App-header">
        <MarketingPricing />
        <MarketingFooter />
    </header>
    </div>
  );
}

export default App;
